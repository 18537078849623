import React from 'react'
import Intro from '../../../components/Intro';
import Section from '../../../components/Section';
import ImgFit from '../../../components/ImgFit';
import Back from '../../../components/Back';
import Pagination from '../../../components/Pagination';
import Title1 from '../../../components/Title1';
import Flex from '../../../components/Flex';
import Helmet from 'react-helmet';
import Logo from './logo-universal.svg';
import styles from '../../../styles/styles.json';
import Layout from "../../../components/layoutProject"
import SEO from "../../../components/seo"

export const frontmatter = {
  id: 1,
  isWork: true,
  url: 'universal',
  title: 'Universal',
  subtitle: 'Identity Recognition Platform',
  path: '/work/universal',
  excerpt: `Identity Recognition Platform`,
};

const UniversalPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Back />
      <div className="notch">
        <Helmet title={`${frontmatter.title} | ${data.site.siteMetadata.title}`} />
        <Intro dark full bg={styles.colors.universal.base} >
          <img className="brand" src={Logo} alt={frontmatter.title} />
          {/* <ImgSmall img={data.light.childImageSharp.fluid.src} /> */}
          <p className='highlight'>Music as a teaching tool to improve your English skills.</p>
          <p>This project involved a dogfight with creative, illustrators, teachers and professional musicians. The intention was to create an educational experience whose hook was music.</p>
        </Intro>

        <Section dark full bg={styles.colors.universal.base} >
          <Title1 title='Immersive experience' />
          <p>Music is a proven teaching tool which fosters language retention. I worked closely with illustrators to create a music world with strong visuals and interactive exercises.</p>
          <Flex>
            <ImgFit img={data.screenLogin.childImageSharp.fluid.src} style="rounded borderdark" />
            <ImgFit img={data.screenHome.childImageSharp.fluid.src} style="rounded borderdark" />
          </Flex>
        </Section>

        <Section dark bg={styles.colors.universal.base} >
          <Title1 title='Challenge accepted' />
          <p>We used gamification to incentive people to use the application by gradually increasing levels of difficulty and providing immediate feedback. </p>
          <Flex>
            <ImgFit img={data.screenStage.childImageSharp.fluid.src} style="rounded borderdark" />
            <ImgFit img={data.screenWords.childImageSharp.fluid.src} style="rounded borderdark" />
          </Flex>
        </Section>

      </div>
      <Pagination currentproject={frontmatter.id} mydata={data} />
    </Layout>
  )
};

export default UniversalPage;

export const query = graphql`
  query GatsbyUniversalQuery {
    site {
      siteMetadata {
        title
      }
    }

    allJavascriptFrontmatter(filter: {frontmatter: {path: {regex: "/work/"}}}, sort: {fields: [frontmatter___id], order: ASC}) {
      edges {
        node {
          frontmatter {
            isWork
            path
            id
            title
            url
          }
        }
      }
    }

    screenLogin: file(relativePath: { eq: "work/universal/screen-login.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
    screenHome: file(relativePath: { eq: "work/universal/screen-home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
    screenStage: file(relativePath: { eq: "work/universal/screenstage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
    screenWords: file(relativePath: { eq: "work/universal/screenwords.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
  
  }
`;